// src/App.js
import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';


const Container = styled.div`
    display: flex;
    justify-content: center;
    margin: 1rem;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px;
  background-color: #151515; /* Fundo escuro */
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  width: 400px; /* Largura fixa para o formulário */
  height: 550px;

  h1{
    margin: 1rem;
  }
`;

const FormField = styled.input`
  margin: 10px 0; /* Espaço entre os campos */
  padding: 12px;
  width: 100%; /* Largura completa */
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #2a2a2a; /* Fundo dos campos */
  color: white; /* Texto branco */
  
  &:focus {
    outline: none; /* Remove o contorno padrão */
  }
`;

const SubmitButton = styled.button`
  padding: 12px;
  background: linear-gradient(to right, #aa7d3c, #f2cf8d);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%; /* Largura completa do botão */

  &:hover {
    background: linear-gradient(to left, #aa7d3c, #f2cf8d);
  }
`;

const StatusMessage = styled.p`
  margin-top: 10px;
  color: #f2cf8d; /* Texto da mensagem de status */
`;

function App() {
  const [formData, setFormData] = useState({
    name: '',
    number: '',
    email: '',
  });
  const [status, setStatus] = useState('');
  const { t } = useTranslation();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Enviar os dados do formulário para o Formspree
    try {
      const response = await fetch('https://formspree.io/f/xyzgrzdd', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setStatus('Mensagem enviada com sucesso!');
        // Resetar o formulário
        setFormData({ name: '', number: '', email: '' });
      } else {
        setStatus('Ocorreu um erro. Tente novamente.');
      }
    } catch (error) {
      setStatus('Erro ao enviar a mensagem. Verifique sua conexão e tente novamente.');
    }
  };

  return (
    <Container>
    <FormContainer>
      <h1 style={{ color: '#fff' }}>{t('contato.title')}</h1>
      <form onSubmit={handleSubmit}>
        <FormField
          type="text"
          name="name"
          placeholder="Sirius"
          value={formData.name}
          onChange={handleChange}
          required
        />
        <FormField
          type="text"
          name="number"
          placeholder="+55 (14)99999-9999"
          value={formData.number}
          onChange={handleChange}
          required
        />
        <FormField
          type="email"
          name="email"
          placeholder="robot@siriusrobot.com"
          value={formData.email}
          onChange={handleChange}
          required
        />
        <SubmitButton type="submit">Enviar</SubmitButton>
      </form>
      {status && <StatusMessage>{t('sucessticket.ticketrma')}</StatusMessage>} {/* Exibe o status do envio */}
    </FormContainer>
    </Container>
  );
}

export default App;
