import React, { useEffect } from "react";
import styled from 'styled-components';
import Local from '../../assets/Location.mp4';

const FadeIn = styled.div`
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 1s ease, transform 0.5s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  
  &.visible {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Container = styled.div`
  background: #111111;
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;

  @media (max-width: 1000px) {
    overflow-x: hidden !important;
  }
`;

const Title = styled.h1`
  color: white;
  margin-bottom: 1rem;
  font-size: 1rem;

  span{
    color: #FFC222;
  }
`;

const Card = styled.div`
  width: 1280px;
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 14px;
  margin: 2rem;

  @media (max-width: 1000px) {
   width: 100%;
   max-width: 400px;
  }
`;

const VideoLoop = styled.video`
  border-radius: 14px;
  width: 650px;
  height: 400px;
  object-fit: cover;
  pointer-events: none;
  margin-bottom: 1rem;

  @media (max-width: 1000px) {
   width: 400px;
  }
`;

function Loca() {

  useEffect(() => {
    const handleScroll = () => {
      const element = document.querySelector('.fade-in-local');
      const elementPosition = element.getBoundingClientRect().top;
      const screenHeight = window.innerHeight;

      if (elementPosition < screenHeight) {
        element.classList.add('visible');
      } else {
        element.classList.remove('visible');
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Container>
      <FadeIn className="fade-in-local">
        <Card>
            <VideoLoop autoPlay loop muted>
              <source src={Local} type="video/mp4" />
            </VideoLoop>
            
        </Card>
      </FadeIn>
    </Container>
  );
}

export default Loca;
