import React, { useEffect, useState } from "react";
import axios from 'axios';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Banner from '../../assets/banner-whatsapp.png';


const StyledLink = styled(Link)`
  color: white;
  text-decoration: none;
  font-weight: bold;
  margin-top: 1rem;

  &:hover {
    text-decoration: underline;
    filter: brightness(0.8);
  } 
  `;

const FadeIn = styled.div`
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 1s ease, transform 1s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  
  &.visible {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Container = styled.div`
  background: #111111;
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;

  @media (max-width: 1000px) {
    overflow-x: hidden !important;
  }
`;

const Card = styled.div`
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 14px;
  margin: 2rem;

  @media (max-width: 1000px) {
   width: 100%;
   max-width: 400px;
  }

  p{
    margin: 1rem;
    background: none;
  }
`;



const Botao = styled.button`
  margin-top: 1rem;
  width: 244px;
  height: 50px;
  background: none;
  cursor: pointer;
  font-weight: 600;
  color: white;
  font-size: 1.2rem;
  border-radius: 10px;
  border: 1px solid white;
  transition: 500ms;
  margin-top: 1rem;

  &:hover{
    background: #FFC222;
    border: none;
  }
`;

const Imagem = styled.img`
  width: 80%;
`;

function Site() {

  const { t } = useTranslation();

  useEffect(() => {
    const handleScroll = () => {
      const element = document.querySelector('.fade-in-contato');
      const elementPosition = element.getBoundingClientRect().top;
      const screenHeight = window.innerHeight;

      if (elementPosition < screenHeight) {
        element.classList.add('visible');
      } else {
        element.classList.remove('visible');
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);



  return (
    <Container>
      <FadeIn className="fade-in-contato">
        <Card>
            <Imagem  src={Banner}/>
            <StyledLink target="_blank" to="https://www.myfxbook.com/members/LEOPEREIRA/robot-premium-2022/2665219">
               <Botao>{t('card.button')}</Botao>
            </StyledLink>
        </Card>
      </FadeIn>
    </Container>
  );
}

export default Site;
