import React, { useState } from "react";
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import IconX from '../../assets/X.png';
import { Link } from 'react-router-dom';
import Skilling01 from "../../assets/skilling_01.png";
import Skilling02 from "../../assets/skilling_02.png";
import Skilling03 from "../../assets/skilling_03.png";
import Skilling04 from "../../assets/skilling_04.png";
import Skilling05 from "../../assets/skilling_05.png";
import Skilling06 from "../../assets/skilling_06.png";
import Skilling07 from "../../assets/skilling_07.png";
import Skilling08 from "../../assets/skilling_08.png";
import Skilling09 from "../../assets/skilling_09.png";
import Skilling10 from "../../assets/skilling_10.png";
import Skilling11 from "../../assets/skilling_11.png";
import Skilling12 from "../../assets/skilling_12.png";
import Header from "../Header/Header2.js";

const StyledLink = styled(Link)`
  color: white;
  text-decoration: none;
  font-weight: bold;
  margin-top: 1rem;

  &:hover {
    text-decoration: underline;
    filter: brightness(0.8);
  } 
`;

const StyledLink2 = styled(Link)`
  color: white;
  text-decoration: none;
  font-weight: bold;
  margin:1rem;
`;


const TitleSub2 = styled.button`
  width: 200px;
  height: 50px;
  background: none;
  cursor: pointer;
  font-weight: 600;
  color: white;
  font-size: 1.2rem;
  border-radius: 8px;
  border: 1px solid #FFC222;
  transition: 500ms;
  margin: 8px;

  &:hover{
    background: #FFC222;
  }
`;

const Fechar = styled.img``;

const Container = styled.div`
  background: #111111;
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;


  @media (max-width: 1000px) {
    overflow-x: hidden !important;
  }
`;

const Title = styled.h1`
  text-align: center;
  width: 100%;
  color: white;
  font-size: 1.5rem;
  font-weight: 600;
  margin: 1rem;
`;

const TitleSub = styled.h1`
  text-align: center;
  width: 100%;
  color: #c3c3c3;
  font-size: 1.3rem;
  font-weight: 400;
  margin-bottom: 1rem;
`;

const Card = styled.div`
  width: 1080px;
  background: #151515;
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 14px;
  margin: 2rem;
  position: relative;

  @media (max-width: 1000px) {
   width: 100%;
   max-width: 400px;
  }
`;

const Botao = styled.button`
  width: 250px;
  height: 50px;
  background: linear-gradient(to right, #bf8b3d, #fefebe, #bf8b3d);
  cursor: pointer;
  font-weight: 600;
  color: black;
  font-size: 1.2rem;
  border-radius: 8px;
  border: none;
  transition: 500ms;
  margin: 8px;

  &:hover{
    filter: brightness(0.8);
  }
`;

const Image = styled.img`
  max-width: 100%;
  margin: 20px;
`;

const BolinhasContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
`;

const Bolinha = styled.div`
  width: 24px;
  height: 10px;
  border-radius: 14px;
  margin: 0 6px;
  cursor: pointer;
  background: ${(props) => (props.selected ? '#fefebe' : '#c3c3c3')};

  @media (max-width: 1000px) {
   width: 10px;
  }
`;

const TextPrincipal = styled.h1`
  color: transparent;
  background: linear-gradient(to right, #bf8b3d, #fefebe, #bf8b3d);
  -webkit-background-clip: text;
  margin-bottom: 1rem;
`;

const SubPrincipal = styled.p`
  color: white;
  font-size: 1.2rem;
`;

const Linkada = styled.a`
  color: transparent;
  margin: 0.5rem;
  transition: 500ms;
  font-size: 1.2rem;
  background: linear-gradient(to right, #bf8b3d, #fefebe, #bf8b3d);
  -webkit-background-clip: text;

  &:hover{
    filter: brightness(0.8);
  }
`;


const images = [Skilling01, Skilling02, Skilling03, Skilling04, Skilling05, Skilling06, Skilling07, Skilling08, Skilling09, Skilling10, Skilling11, Skilling12];

function Site() {
    const { t } = useTranslation();
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
  
    const nextImage = (index) => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const imageInfo = [
        {
            title: t('skilling01'),
            cont: t('skillingT01'),
        },
        {
            title: t('skilling02'),
            cont: t('skillingT02')
        },
        {
          title: t('skilling03'),
          cont: t('skillingT03')
        },
        {
          title: t('skilling04'),
          cont: t('skillingT04')
        },
        {
          title: t('skilling05'),
          cont: t('skillingT05')
        },
        {
          title: t('skilling06'),
          cont: t('skillingT06')
        },
        {
          title: t('skilling07'),
          cont: t('skillingT07')
        },
        {
          title: t('skilling08'),
          cont: t('skillingT08')
        },
        {
          title: t('skilling09'),
          cont: t('skillingT09')
        },
        {
          title: t('skilling10'),
          cont: t('skillingT10')
        },
        {
          title: t('skilling11'),
          cont: t('skillingT11')
        },
        {
          title: t('skilling12'),
          cont: t('skillingT12')
        },

    ];
    
    return (
        <Container>
            <Header/>
            <StyledLink to={t('back.language')}>
                <Fechar src={IconX} alt="Fechar"/>   
            </StyledLink>
            <Card>
                <TextPrincipal>{t('atencao.title')}</TextPrincipal>
                <Linkada href="https://br.trustpilot.com/" target="_blank">{t('trustpilot')}</Linkada>
                <SubPrincipal>{t('atencao.sub1')}</SubPrincipal>
                <Linkada href="https://skilling.com/row/pt/" target="_blank">Skilling EU</Linkada>
               
                <SubPrincipal>{t('atencao.sub2')}</SubPrincipal>
                <Linkada href="https://skilling.com/?utm_source=6d1917f5-dfc5-4455-bd71-ac322088f28d&utm_medium=ib" target="_blank">Skilling</Linkada>
                <Image src={images[currentImageIndex]} alt={`Skilling ${currentImageIndex + 1}`} />
                <BolinhasContainer>
                    {images.map((_, index) => (
                        <Bolinha
                            key={index}
                            selected={index === currentImageIndex}
                            onClick={() => setCurrentImageIndex(index)} // Alterado aqui
                        />
                    ))}
                </BolinhasContainer>
                <Title>{imageInfo[currentImageIndex].cont}</Title>
                <TitleSub>{imageInfo[currentImageIndex].title}</TitleSub>
                {currentImageIndex === 11 && (
                  <Linkada href="/mt4-skiling">
                     {t('conta.MT4')}
                  </Linkada>
                )}
                <Botao onClick={nextImage}>{t('next.button')}</Botao>
            </Card>
        </Container>
    );
}

export default Site;
