import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Ptlanguage from '../locales/pt.json';
import Enlanguage from '../locales/en.json';
import Eslanguage from '../locales/es.json';
import Frlanguage from '../locales/fr.json';
import Arlanguage from '../locales/ar.json';
import Cnlanguage from '../locales/cn.json';

i18n
  .use(initReactI18next)
  .init({
    // Configurações e opções do i18next
    lng: 'en', // Define o idioma padrão
    resources: {
      en: {
         ...Enlanguage
      },
      pt: {
          ...Ptlanguage
      },
      es: {
        ...Eslanguage
     },
     fr: {
      ...Frlanguage
     },
     ar: {
      ...Arlanguage
     },
     cn: {
      ...Cnlanguage
     }
    }
  });
