import React from "react";
import styled from 'styled-components';
import VideoPT from '../../assets/videos/CHINES.mp4';
import { useTranslation } from 'react-i18next';

const VideoPlayer = styled.video`
  width: 1280px;
  display: block;
  margin: 1rem auto;
  cursor: pointer;

  @media (max-width: 1000px) {
    width: 100%;
  }
`;

const HomeContainer = styled.div`
  z-index: 1;
  @media (max-width: 1000px) {
    overflow-x: hidden !important;
  }
`;

function Home() {
  const { t } = useTranslation();

  return (
      <HomeContainer>
        <VideoPlayer controls>
           <source src={VideoPT} type="video/mp4" />
        </VideoPlayer>
      </HomeContainer>
  );
}

export default Home;
