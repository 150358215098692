import React, { useEffect, useState } from "react";
import axios from 'axios';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import IconX from '../../assets/X.png';


const StyledLink = styled(Link)`
  color: white;
  text-decoration: none;
  font-weight: bold;
  margin-top: 1rem;

  &:hover {
    text-decoration: underline;
    filter: brightness(0.8);
  } 
  `;

const Fechar = styled.img``;


const Container = styled.div`
  background: #111111;
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media (max-width: 1000px) {
    overflow-x: hidden !important;
  }
`;

const Title = styled.h1`
  color: white;
  margin-bottom: 2rem;

  span{
    color: #FFC222;
  }
`;

const Card = styled.div`
  width: 1080px;
  background: #151515;
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 14px;
  margin: 2rem;

  @media (max-width: 1000px) {
   width: 100%;
   max-width: 400px;
  }

  p{
    margin: 1rem;
    background: none;
  }
`;



const Botao = styled.button`
  width: 250px;
  height: 50px;
  background: #FFC222;
  cursor: pointer;
  font-weight: 600;
  color: white;
  font-size: 1.2rem;
  border-radius: 8px;
  border: none;
  transition: 500ms;

  &:hover{
    filter: brightness(0.8);
  }
`;

const ControlAll = styled.div`
  display: flex;
  justify-content: center;
  align-items: left;
  flex-direction: column;
`;

const ControllNumber = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
`;

const ControllInput2 = styled.div`
  display: flex;
  justify-content: center;
  align-items: left;
  flex-direction: column;
  margin-bottom: 1rem;
`;

const NameInput = styled.label`
  color: white;
  margin-bottom: 8px;
`;

const InputDDD = styled.input`
  width: 105px;
  height: 60px;
  background: #343333;
  border: 1px solid #8D8D8D;
  border-radius: 10px;
  color: white;
  padding: 1rem;
  font-size: 1rem;
  margin-right: 10px;
`;

const InputNumber = styled.input`
  width: 145px;
  height: 60px;
  background: #343333;
  border: 1px solid #8D8D8D;
  border-radius: 10px;
  padding: 6px;
  color: white;
  font-size: 1rem;
`;

const Input = styled.input`
  width: 260px;
  height: 60px;
  background: #343333;
  border: 1px solid #8D8D8D;
  border-radius: 10px;
  color: white;
  padding: 1rem;
  font-size: 1rem;
  margin-bottom: 14px;
`;

function Site() {
  const [nome, setNome] = useState('');
  const [email, setEmail] = useState('');
  const [whatsapp, setWhatsapp] = useState('');
  const [code, setCode] = useState('');
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const enviarEmail = () => {
    setButtonDisabled(true);
    if (!nome || !email || !whatsapp || !code) {
      setShowErrorMessage(true);
      setTimeout(() => {
        setShowErrorMessage(false);
        setButtonDisabled(false);
      }, 3000);
      return;
    }

    const commentBody = `
    Nome: ${nome}
    Email: ${email}
    Telefone: ${code} ${whatsapp}
  `;

    axios
    .post('https://riserob.zendesk.com/api/v2/tickets.json', {
      ticket: {
        subject: 'Contato Rise Robô - ' + nome,
        comment: {
          body: commentBody + '\nEnviado via site: Rise Robô'
        },
        requester: {
          name: nome,
          email: email,
          phone: code+whatsapp,
        },
        tags: ['riserobo', 'site', 'contact'], // Adicione as tags desejadas aqui
        type: 'question', // Adicione o tipo de ticket desejado aqui
        via: {
          channel: 'web' // Define o canal como 'web' para indicar que o ticket veio do seu site
        },
      }
    }, {
      auth: {
        username: 'Manager@riserobo.com/token',
        password: 'hzlcHluyti88HVTWaqO42tNkQLNTyolGkqvY2WVM'
      }
    })
    .then(response => {
      setShowSuccessMessage(true);
      setTimeout(() => {
        setShowSuccessMessage(false);
      }, 5000);
      limparCampos();
    })
    .catch(error => {
      setShowSuccessMessage(true);
      setTimeout(() => {
        setShowSuccessMessage(false);
      }, 5000);
      limparCampos();
    })
    .then(() => {
      setButtonDisabled(false);
    });
};

  const limparCampos = () => {
    setNome('');
    setEmail('');
    setWhatsapp('');
    setCode('');
  };

  const handleWhatsappChange = (e) => {
    const inputValue = e.target.value;
    const formattedValue = inputValue.replace(/[^\d()+-]/g, '');
    setWhatsapp(formattedValue);
  };

  const { t } = useTranslation();

  useEffect(() => {
    const handleScroll = () => {
      const element = document.querySelector('.fade-in-contato');
      const elementPosition = element.getBoundingClientRect().top;
      const screenHeight = window.innerHeight;

      if (elementPosition < screenHeight) {
        element.classList.add('visible');
      } else {
        element.classList.remove('visible');
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);




  return (
    <Container>
        <StyledLink to="/">
             <Fechar src={IconX} alt="Fechar"/>   
        </StyledLink>
        <Card>
            <Title>{t('contato.title')} <span>.</span></Title>
            <ControlAll>
              <ControllNumber>
                <ControllInput2>
                    <NameInput >{t('contato.codigo')}</NameInput>
                    <InputDDD placeholder="+55" value={code} onChange={e => setCode(e.target.value)}/>
                </ControllInput2>
                <ControllInput2>
                    <NameInput>{t('contato.telefone')}</NameInput>
                    <InputNumber  placeholder="(99) 999999-99999" value={whatsapp} onChange={handleWhatsappChange}/>
                </ControllInput2>
              </ControllNumber>

            <NameInput >{t('contato.nome')}</NameInput>
            <Input type="text" placeholder="João" value={nome} onChange={e => setNome(e.target.value)}/>
            <NameInput >E-mail</NameInput>
            <Input type="email" placeholder="rise@email.com" value={email} onChange={e => setEmail(e.target.value)}/>    
            </ControlAll>
            {showErrorMessage && <p style={{ color: 'red' }}>{t('alert.campos')}</p>}
            {showSuccessMessage && <p style={{ color: 'green' }}>{t('sucess.contact')}</p>}
            <Botao disabled={buttonDisabled} onClick={enviarEmail} alt="Enviar" title="Enviar">{t('contato.botao')}</Botao>
        </Card>
    </Container>
  );
}

export default Site;
