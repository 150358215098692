import React, { useEffect } from "react";
import styled from 'styled-components';
import FotoRobo1 from '../../assets/ROBO-ART.png';
import { useTranslation } from 'react-i18next';
import { Element } from 'react-scroll';
import { Link } from 'react-router-dom';


const StyledLink = styled(Link)`
  color: white;
  text-decoration: none;
  font-weight: bold;
  margin-top: 1rem;

  @media (max-width: 1000px) {
    display: flex;
    justify-content: center;
  }

  `;


const Container = styled.div`
  background: #151515;
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  overflow: hidden;

  @media (max-width: 1000px) {
    overflow-x: hidden !important;
    flex-direction: column;
  }
`;

const Div = styled.div`
  width: 700px;
  padding: 2rem;
  display: flex;
  justify-content: center;
  flex-direction: column;

  @media (max-width: 1000px) {
    width: 100%;
  }
`;


const CardMeio = styled.div`
    width:500px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Imagem = styled.img`
  width: 600px;
  transform: translate(-50,-50);

  @media (max-width: 1000px) {
    width: 100%;
    max-width: 300px;
  }

`;

const Title = styled.h1`
  font-weight: bold;
  color: white;
  font-size: 42px;

    span{
    color: white;
  }

  @media (max-width: 1000px) {
    width: 100%;
    text-align: center;
    font-size: 18px;
    }
`;

const Botao = styled.button`
  margin-top: 8px;
  width: 250px;
  height: 50px;
  background: linear-gradient(to right, #aa7d3c, #f2cf8d);
  cursor: pointer;
  font-weight: 600;
  color: Black;
  font-size: 1.2rem;
  border-radius: 10px;
  border: none;
  transition: 500ms;

  &:hover{
    border: 1px solid white;
  }

  @media (max-width: 1000px) {
        margin-bottom: 2rem;
        width: 250px;
    }


`;


function Card() {
    const { t } = useTranslation();


  return (

    <Container>
        <CardMeio>
            <Imagem src={FotoRobo1} alt="Robo"/>
        </CardMeio>
        <Div>  
            <Title>{t('results.title')}<span>:</span></Title>
            <StyledLink target="_blank" to="https://www.myfxbook.com/members/LEOPEREIRA/robot-premium-2022/2665219">
                      <Botao>{t('card.button')}</Botao>
            </StyledLink>
        </Div>
    </Container>

  );
}

export default Card;
