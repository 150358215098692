import React, { useEffect } from "react";
import styled from 'styled-components';
import WomanPhoto from '../../assets/myfx.png';
import { useTranslation } from 'react-i18next';
import { Element } from 'react-scroll';
import { Link } from 'react-router-dom';


const StyledLink = styled(Link)`
  color: white;
  text-decoration: none;
  font-weight: bold;
  margin-top: 1rem;

  `;


const FadeIn = styled.div`
  opacity: 0;
  transform: translateY(50px); /* Aumentei a quantidade de translação */
  transition: opacity 1s ease, transform 1s ease; /* Reduzi a duração da transição */
  
  &.visible {
    opacity: 1;
    transform: translateY(0);
  }
`;


const Container = styled.div`
  background: #151515;
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;

  @media (max-width: 1000px) {
    overflow-x: hidden !important;
  }
`;


const ControlCard = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 3rem;

    @media (max-width: 1000px) {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
`;


const Imagem = styled.img`
    width: 100%;
    margin-right: 1.9rem;

    @media (max-width: 1000px) {
        margin-bottom: 1.5rem;
    }
`;

const ControlTexto = styled.div`
    width: 100%;
    max-width: 450px;
`;

const Title = styled.h1`
  font-weight: bold;
  background: linear-gradient(to right, #aa7d3c, #f2cf8d);
  -webkit-background-clip: text;
  color: transparent;
  display: inline-block;
  margin-right: 10px;
  font-size: 42px;

    span{
    color: #FFC222;
  }
`;

const Text = styled.p`
    color: #c3c3c3;
    font-weight: 400;
    font-size: 1.3rem;
`;


const Botao = styled.button`
  margin-top: 1rem;
  width: 244px;
  height: 50px;
  background: linear-gradient(to right, #aa7d3c, #f2cf8d);
  cursor: pointer;
  font-weight: 600;
  color: black;
  font-size: 1.2rem;
  border-radius: 10px;
  border: none;
  transition: 500ms;
  margin-top: 2rem;

  &:hover{
    border: 1px solid white;
  }

  @media (max-width: 1000px) {
        margin-bottom: 1.5rem;
    }

`;


function Card() {
    const { t } = useTranslation();

    useEffect(() => {
        const handleScroll = () => {
          const element = document.querySelector('.fade-in2');
          const elementPosition = element.getBoundingClientRect().top;
          const screenHeight = window.innerHeight;
    
          if (elementPosition < screenHeight) {
            element.classList.add('visible');
          } else {
            element.classList.remove('visible'); // Remove a classe se o elemento não estiver mais visível
          }
        };
    
        window.addEventListener('scroll', handleScroll);
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);

  return (

    <Container>
        <Element name="section">
        <FadeIn className="fade-in2">
        <ControlCard>
        <Imagem src={WomanPhoto} alt="Foto Woman"/>
            <ControlTexto>
                <Title>Myfxbook</Title>
                <Text>{t('section.text.2')}</Text>
                <StyledLink target="_blank" to="https://www.myfxbook.com/members/LEOPEREIRA/robot-premium-2022/2665219">
                    <Botao>{t('card.button')}</Botao>
                </StyledLink>
            </ControlTexto>
        </ControlCard>
        </FadeIn>
    </Element>
    </Container>

  );
}

export default Card;
