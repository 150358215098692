import React from "react";
import Rotas from './rotas.js';
import './global.css';
import './lib/i18n.js';

function App() {
  return (
    <div className="App">
      <Rotas/>
    </div>
  );
}

export default App;
