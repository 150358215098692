import React from 'react';

const PDFViewer = () => {
  // Caminho para o PDF, pode ser um arquivo local ou URL de um servidor
  const pdfFile = '/4XC-BENEFICIOS.pdf';

  return (
    <div style={{ width: '100%', height: '100vh' }}>
      <iframe
        src={pdfFile}
        width="100%"
        height="100%"
        title="PDF Viewer"
        style={{ border: 'none' }}
      />
    </div>
  );
};

export default PDFViewer;
